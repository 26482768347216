import React from "react";
import "./AppDownloadScreen.css";

import AppScreen_1_Image from "../../images/app-screen-1.png";
import AppScreen_2_Image from "../../images/app-screen-2.png";
import AppScreen_3_Image from "../../images/app-screen-3.png";
import AppstoreImage from "../../images/appstore-icon.png";
import GooglePlaystoreImage from "../../images/google-playstore-icon.png";
import {
  APP_STORE_URL,
  GOOGLE_PLAY_STORE_URL,
} from "../../utils/GlobalVariables";

export default function AppDownloadScreen() {
  const gotoAppStore = (source) => {
    let url;
    if (source === "GOOGLE") {
      url = GOOGLE_PLAY_STORE_URL;
    }
    if (source === "APPLE") {
      url = APP_STORE_URL;
    }

    window.open(url, "_blank");
  };

  return (
    <div
      className="app-download-screen flex-container-space"
      style={{ flexWrap: "wrap" }}
    >
      <div className="download-content">
        <h3>
          Download the <span>LeedsApp</span> Now
        </h3>
        <p>
          Improve your sales <span>10x</span> times by adopting LeedsApp's
          outstanding leads followup system.
        </p>

        <div className="flex-container-start">
          <img
            onClick={(e) => gotoAppStore("APPLE")}
            src={AppstoreImage}
            alt="app store image"
          />
          <img
            onClick={(e) => gotoAppStore("GOOGLE")}
            src={GooglePlaystoreImage}
            alt="google play store image"
          />
        </div>
      </div>

      <div className="app-screens">
        <img src={AppScreen_1_Image} alt="leedsApp screens 1" />
        <img src={AppScreen_2_Image} alt="leedsApp screens 2" />
        <img src={AppScreen_3_Image} alt="leedsApp screens 3" />
      </div>
    </div>
  );
}
