import React, { useState } from "react";
import "./App.css";

import { Redirect, Route, Switch } from "react-router-dom";
import AppDownloadScreen from "./screens/AppDownloadScreen/AppDownloadScreen";
import {
  LEEDSAPP_INFO_WEBSITE_URL,
  LeedsAppLogoImage,
} from "./utils/GlobalVariables";

//---------  material components ------------

// -----------  material icons -------------

//---------  custom components ------------

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [showLanguageScreen, setShowLanguageScreen] = useState(true);
  const [showQuestionsScreen, setShowQuestionsScreen] = useState(false);

  const onLanguageSelected = (s_language) => {
    setSelectedLanguage(s_language);
    setShowLanguageScreen(!showLanguageScreen);
    setShowQuestionsScreen(!showQuestionsScreen);
  };

  const scrollToTop = () => {
    document
      .getElementById("scroll-container")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="app-container">
      <Header />
      <div className="main-body" id="scroll-container">
        <Switch>
          {/* <Route
            exact
            path="/"
            render={(props) => {
              return <div></div>;
            }}
          /> */}

          <Route
            exact
            path="/download"
            render={(props) => {
              return <AppDownloadScreen {...props} />;
            }}
          />

          <Route render={() => <Redirect to={{ pathname: "/download" }} />} />
        </Switch>

        {/* {showLanguageScreen && (
          <LanguageSelectionScreen onLanguageSelected={onLanguageSelected} />
        )}

        {showQuestionsScreen && (
          <Slide in={showQuestionsScreen} direction="left" timeout={400}>
            <div>
              {showQuestionsScreen && (
                <QuestionsScreen
                  selectedLanguage={selectedLanguage}
                  scrollToTop={scrollToTop}
                />
              )}
            </div>
          </Slide>
        )} */}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default App;

const Header = () => {
  const gotoLeedsAppWebsite = () => {
    window.open(LEEDSAPP_INFO_WEBSITE_URL, "_blank");
  };

  const refreshWebsite = () => {
    window.location.reload();
  };

  return (
    <div className="app-header">
      <img
        onClick={refreshWebsite}
        src={LeedsAppLogoImage}
        alt="leedsapp-logo"
      />
      {/* <Button
        variant="contained"
        color="default"
        size="small"
        style={{
          textTransform: "none",
          backgroundColor: "var(--app-icon-color)",
          color: "white",
          fontSize: isMobile ? "16px" : "18px",
          borderRadius: isMobile ? "5px" : "10px",
          padding: isMobile ? "6px 15px" : "8px 20px",
        }}
        onClick={gotoLeedsAppWebsite}
        startIcon={
          <InfoOutlinedIcon style={{ fontSize: isMobile ? "18px" : "20px" }} />
        }
      >
        More Info
      </Button> */}
    </div>
  );
};

const Footer = () => {
  return (
    <div className="app-footer">
      <p>© 2023 LeedsApp</p>
    </div>
  );
};
