import LeedsAppLogoImage from "../images/leedsapp-logo-red-black-text.png";

const english = "English";
const tamil = "தமிழ்";
const hindi = "हिन्दी";
const malayalam = "മലയാളം";
const telugu = "తెలుగు";
const kannada = "ಕನ್ನಡ";

const languagesList = [english, tamil, hindi, malayalam, telugu, kannada];

const englishQuestionsList = [
  "Losing business opportunities due to forgetfulness?",
  "Busy work making you forget to call  potential leads?",
  "Do you struggle to plan your daily tasks and lack clarity?",
];

const tamilQuestionsList = [
  "மறதியால் தொழில் வாய்ப்புகளை இழக்கிறீர்களா?",
  "பிஸியான வேலையின் காரணமாக லீட்களை அழைக்க மறந்துவிட்டீர்களா?",
  "உங்கள் அன்றாடப் பணிகளைத் திட்டமிடுவதில் நீங்கள் சிரமப்படுகிறீர்களா மற்றும் தெளிவு இல்லாமல் இருக்கிறீர்களா?",
];

const hindiQuestionsList = [
  "भूलने की बीमारी के कारण व्यवसाय के अवसर खो रहे हैं?",
  "क्या आप काम की व्यस्तता के कारण लीड्स को कॉल करना भूल जाते हैं?",
  "क्या आपको अपने दैनिक कार्यों की योजना बनाने में कठिनाई होती है और स्पष्टता की कमी है?",
];

const teluguQuestionsList = [
  "మతిమరుపు వల్ల వ్యాపార అవకాశాలు కోల్పోతున్నారా?",
  "బిజీ వర్క్ కారణంగా లీడ్‌లకు కాల్ చేయడం మర్చిపోయారా?",
  "మీరు మీ రోజువారీ పనులను ప్లాన్ చేసుకోవడంలో ఇబ్బంది పడుతున్నారా మరియు స్పష్టత లేకున్నారా?",
];

const kannadaQuestionsList = [
  "ಮರೆವಿನ ಕಾರಣ ವ್ಯಾಪಾರ ಅವಕಾಶಗಳನ್ನು ಕಳೆದುಕೊಳ್ಳುವುದೇ?",
  "ಬಿಡುವಿಲ್ಲದ ಕೆಲಸದ ಕಾರಣದಿಂದಾಗಿ ಲೀಡ್‌ಗಳಿಗೆ ಕರೆ ಮಾಡಲು ನೀವು ಮರೆಯುತ್ತೀರಾ?",
  "ನಿಮ್ಮ ದೈನಂದಿನ ಕಾರ್ಯಗಳನ್ನು ಯೋಜಿಸಲು ನೀವು ಹೆಣಗಾಡುತ್ತೀರಾ ಮತ್ತು ಸ್ಪಷ್ಟತೆಯ ಕೊರತೆಯಿದೆಯೇ?",
];

const malayalamQuestionsList = [
  "മറവി കാരണം ബിസിനസ്സ് അവസരങ്ങൾ നഷ്ടപ്പെടുന്നുണ്ടോ?",
  "തിരക്കുള്ള ജോലി കാരണം ലീഡുകളിലേക്ക് വിളിക്കാൻ നിങ്ങൾ മറക്കുന്നുണ്ടോ?",
  "നിങ്ങളുടെ ദൈനംദിന ജോലികൾ ആസൂത്രണം ചെയ്യാൻ നിങ്ങൾ പാടുപെടുന്നുണ്ടോ, വ്യക്തതയില്ലേ?",
];

const questionsMap = {
  [english]: englishQuestionsList,
  [tamil]: tamilQuestionsList,
  [hindi]: hindiQuestionsList,
  [malayalam]: malayalamQuestionsList,
  [telugu]: teluguQuestionsList,
  [kannada]: kannadaQuestionsList,
};

const englishButtonText = "Yes, I do";
const tamilButtonText = "ஆம், நான் செய்கிறேன்";
const hindiButtonText = "हा करता हु";
const malayalamButtonText = "അതെ ഞാൻ മനസ്സിലാക്കുന്നു";
const teluguButtonText = "అవును నేను చేస్తా";
const kannadaButtonText = "ಹೌದು";

const buttonTextMap = {
  [english]: englishButtonText,
  [tamil]: tamilButtonText,
  [hindi]: hindiButtonText,
  [malayalam]: malayalamButtonText,
  [telugu]: teluguButtonText,
  [kannada]: kannadaButtonText,
};

const APP_STORE_URL =
  "https://itunes.apple.com/us/app/apple-store/id6453606859?mt=8";
const GOOGLE_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.leedsapp";

const LEEDSAPP_INFO_WEBSITE_URL = "https://info.leedsapp.com";

export {
  languagesList,
  englishQuestionsList,
  tamilQuestionsList,
  hindiQuestionsList,
  teluguQuestionsList,
  kannadaQuestionsList,
  malayalamQuestionsList,
  questionsMap,
  buttonTextMap,
  LeedsAppLogoImage,
  APP_STORE_URL,
  GOOGLE_PLAY_STORE_URL,
  LEEDSAPP_INFO_WEBSITE_URL,
};
